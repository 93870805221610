import * as React from "react"
import Layout from "../components/layout";

// Styles
import "../styles.css"

import Content from "../content";
import Container from "../theme/container";
import Heading from "../theme/heading";

const Credits = () => {
    return (
        <>
            <Layout contact={Content.Contact}>
                <section>
                    <Container>
                        <Heading as="h1" align="center">Credits</Heading>
                            <ul>
                                <li>
                                    <a href="https://www.flaticon.com/free-icons/malware" title="malware icons">Malware icons created by Valueinvestor - Flaticon</a>
                                </li>
                                    
                                <li>
                                    <a href="https://www.flaticon.com/free-icons/stealing-data" title="stealing data icons">Stealing data icons created by SBTS2018 - Flaticon</a>
                                </li>
                                    
                                <li>
                                    <a href="https://www.flaticon.com/free-icons/cyber-attack" title="cyber attack icons">Cyber attack icons created by Freepik - Flaticon</a>
                                </li>
                                    
                                <li>
                                    <a href="https://www.flaticon.com/free-icons/coverage" title="coverage icons">Coverage icons created by mavadee - Flaticon</a>
                                </li>
                                    
                            </ul>
                    </Container>
                </section>

            </Layout>
        </>
    );
}

export default Credits;